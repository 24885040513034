import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/apps/profile/AccountPage'))
  const AcademicYearsPage = lazy(() => import('../modules/apps/academic-year/AcademicYearsPage'))
  const AccountsPage = lazy(() => import('../modules/apps/account/AccountsPage'))
  const AdmissionsPage = lazy(() => import('../modules/apps/admission/AdmissionsPage'))
  const AssetCategorysPage = lazy(() => import('../modules/apps/asset-category/AssetCategorysPage'))
  const AssetItemsPage = lazy(() => import('../modules/apps/asset-item/AssetItemsPage'))
  const AssetIssuesPage = lazy(() => import('../modules/apps/asset-issue/AssetIssuesPage'))
  const AssetPurchasesPage = lazy(() => import('../modules/apps/asset-purchase/AssetPurchasesPage'))
  const AssetStoresPage = lazy(() => import('../modules/apps/asset-store/AssetStoresPage'))
  const AssignmentSubmissionsPage = lazy(
    () => import('../modules/apps/assignment-submission/AssignmentSubmissionsPage')
  )
  const AssignmentsPage = lazy(() => import('../modules/apps/assignment/AssignmentsPage'))
  const BooksPage = lazy(() => import('../modules/apps/book/BooksPage'))
  const ClasssPage = lazy(() => import('../modules/apps/class/ClasssPage'))
  const ComplainsPage = lazy(() => import('../modules/apps/complain/ComplainsPage'))
  const DepartmentsPage = lazy(() => import('../modules/apps/department/DepartmentsPage'))
  const DesignationsPage = lazy(() => import('../modules/apps/designation/DesignationsPage'))
  const EbooksPage = lazy(() => import('../modules/apps/ebook/EbooksPage'))
  const EmployeeAttendancesPage = lazy(
    () => import('../modules/apps/employee-attendance/EmployeeAttendancesPage')
  )
  const EmployeesPage = lazy(() => import('../modules/apps/employee/EmployeesPage'))
  const ExamAttendancesPage = lazy(
    () => import('../modules/apps/exam-attendance/ExamAttendancesPage')
  )
  const ExamPsychomotorsPage = lazy(
    () => import('../modules/apps/exam-psychomotor/ExamPsychomotorsPage')
  )
  const ExamResultsPage = lazy(() => import('../modules/apps/exam-result/ExamResultsPage'))
  const ExamSchedulesPage = lazy(() => import('../modules/apps/exam-schedule/ExamSchedulesPage'))
  const ExamsPage = lazy(() => import('../modules/apps/exam/ExamsPage'))
  const GalleryImagesPage = lazy(() => import('../modules/apps/gallery-image/GalleryImagesPage'))
  const ExpendituresPage = lazy(() => import('../modules/apps/expenditure/ExpendituresPage'))
  const DiscountsPage = lazy(() => import('../modules/apps/discount/DiscountsPage'))
  const InvoicesPage = lazy(() => import('../modules/apps/invoice/InvoicesPage'))
  const FeeTypesPage = lazy(() => import('../modules/apps/fee-type/FeeTypesPage'))
  const GallerysPage = lazy(() => import('../modules/apps/gallery/GallerysPage'))
  const FinalResultsPage = lazy(() => import('../modules/apps/final-result/FinalResultsPage'))
  const GradesPage = lazy(() => import('../modules/apps/grade/GradesPage'))
  const HostelMembersPage = lazy(() => import('../modules/apps/hostel-member/HostelMembersPage'))
  const HostelsPage = lazy(() => import('../modules/apps/hostel/HostelsPage'))
  const LibraryMembersPage = lazy(() => import('../modules/apps/library-member/LibraryMembersPage'))
  const RoomsPage = lazy(() => import('../modules/apps/room/RoomsPage'))
  const MarksPage = lazy(() => import('../modules/apps/mark/MarksPage'))
  const NewsPage = lazy(() => import('../modules/apps/news/NewsPage'))
  const PrivilegesPage = lazy(() => import('../modules/apps/privilege/PrivilegesPage'))
  const ResultCardsPage = lazy(() => import('../modules/apps/result-card/ResultCardsPage'))
  const RolesPage = lazy(() => import('../modules/apps/role/RolesPage'))
  const SectionsPage = lazy(() => import('../modules/apps/section/SectionsPage'))
  const Scholarship = lazy(() => import('../modules/apps/ss-scholarship/SsScholarshipsPage'))
  const SsDonorPage = lazy(() => import('../modules/apps/ss-donor/SsDonorsPage'))
  const SsDonationPage = lazy(() => import('../modules/apps/ss-donation/SsDonationsPage'))
   const SsCandidatePage = lazy(() => import('../modules/apps/ss-candidate/SsCandidatesPage'))
  const SchoolsPage = lazy(() => import('../modules/apps/school/SchoolsPage'))
  const SlidersPage = lazy(() => import('../modules/apps/slider/SlidersPage'))
  const AboutsPage = lazy(() => import('../modules/apps/about/AboutsPage'))
  const StudentAttendancesPage = lazy(
    () => import('../modules/apps/student-attendance/StudentAttendancesPage')
  )
  const StudentTypesPage = lazy(() => import('../modules/apps/student-type/StudentTypesPage'))
  const StudentsPage = lazy(() => import('../modules/apps/student/StudentsPage'))
  const SubjectsPage = lazy(() => import('../modules/apps/subject/SubjectsPage'))
  const SyllabussPage = lazy(() => import('../modules/apps/syllabus/SyllabussPage'))
  const ClassLecturesPage = lazy(() => import('../modules/apps/class-lecture/ClassLecturesPage'))
  const PromotionsPage = lazy(() => import('../modules/apps/promotion/PromotionsPage'))
  const IdCardsPage = lazy(() => import('../modules/apps/id-card/IdCardsPage'))
  const TransactionsPage = lazy(() => import('../modules/apps/transaction/TransactionsPage'))
  const StudyMaterialsPage = lazy(() => import('../modules/apps/study-material/StudyMaterialsPage'))
  const RoutinesPage = lazy(() => import('../modules/apps/routine/RoutinesPage'))

  const AssetLeaveApplicationsPage = lazy(
    () => import('../modules/apps/leave-application/LeaveApplicationsPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='profile/*'
          element={
            <SuspensedView1>
              <AccountPage />
            </SuspensedView1>
          }
        />
        <Route
          path='apps/admin/academic-year/*'
          element={
            <SuspensedView model='academic-year'>
              <AcademicYearsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/admin/account/*'
          element={
            <SuspensedView model='account'>
              <AccountsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/student-management/admission/*'
          element={
            <SuspensedView model='admission'>
              <AdmissionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/assignment-submission/*'
          element={
            <SuspensedView model='assignment-submission'>
              <AssignmentSubmissionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/category/*'
          element={
            <SuspensedView model='category'>
              <AssetCategorysPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/item/*'
          element={
            <SuspensedView model='item'>
              <AssetItemsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/purchase/*'
          element={
            <SuspensedView model='purchase'>
              <AssetPurchasesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/todo/*'
          element={
            <SuspensedView model='issue'>
              <AssetIssuesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/store/*'
          element={
            <SuspensedView model='store'>
              <AssetStoresPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/assignment/*'
          element={
            <SuspensedView model='assignment'>
              <AssignmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/class/*'
          element={
            <SuspensedView model='class'>
              <ClasssPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/book/*'
          element={
            <SuspensedView model='book'>
              <BooksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/complain/*'
          element={
            <SuspensedView model='complain'>
              <ComplainsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/discount/*'
          element={
            <SuspensedView model='discount'>
              <DiscountsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/transaction/*'
          element={
            <SuspensedView model='discount'>
              <TransactionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ebook/*'
          element={
            <SuspensedView model='ebook'>
              <EbooksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/employee-management/department/*'
          element={
            <SuspensedView model='department'>
              <DepartmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/employee-management/designation/*'
          element={
            <SuspensedView model='designation'>
              <DesignationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/employee-management/employee-attendance/*'
          element={
            <SuspensedView model='employee-attendance'>
              <EmployeeAttendancesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/employee-management/employee/*'
          element={
            <SuspensedView model='employee'>
              <EmployeesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/exam/exam-attendance/*'
          element={
            <SuspensedView model='exam-attendance'>
              <ExamAttendancesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/expenditure/*'
          element={
            <SuspensedView model='expenditure'>
              <ExpendituresPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/examresult/*'
          element={
            <SuspensedView model='exam-result'>
              <ExamResultsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/fee-type/*'
          element={
            <SuspensedView model='invoice'>
              <FeeTypesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/invoice/*'
          element={
            <SuspensedView model='invoice'>
              <InvoicesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/promotion/*'
          element={
            <SuspensedView model='promotion'>
              <PromotionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/exam/schedule/*'
          element={
            <SuspensedView model='exam-schedule'>
              <ExamSchedulesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/exam/exam/*'
          element={
            <SuspensedView model='exam'>
              <ExamsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/gallery/image/*'
          element={
            <SuspensedView model='gallery-image'>
              <GalleryImagesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/gallery/*'
          element={
            <SuspensedView model='gallery'>
              <GallerysPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/finalresult/*'
          element={
            <SuspensedView model='final-result'>
              <FinalResultsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/exam/grade/*'
          element={
            <SuspensedView model='grade'>
              <GradesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/id-cards/*'
          element={
            <SuspensedView model='id-card'>
              <IdCardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/member/*'
          element={
            <SuspensedView model='hostel-member'>
              <HostelMembersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/hostel/*'
          element={
            <SuspensedView model='hostel'>
              <HostelsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/library-member/*'
          element={
            <SuspensedView model='library-member'>
              <LibraryMembersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/application/*'
          element={
            <SuspensedView model='leave-application'>
              <AssetLeaveApplicationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/room/*'
          element={
            <SuspensedView model='room'>
              <RoomsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/mark/*'
          element={
            <SuspensedView model='mark'>
              <MarksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/marksheet/*'
          element={
            <SuspensedView model='marksheet'>
              <MarksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/psychomotor/marksheet/*'
          element={
            <SuspensedView model='psychomotor'>
              <ExamPsychomotorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/frontend/news/*'
          element={
            <SuspensedView model='news'>
              <NewsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/admin/permission/*'
          element={
            <SuspensedView model='permission'>
              <PrivilegesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/resultcard/*'
          element={
            <SuspensedView model='resultcard'>
              <ResultCardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/admin/role/*'
          element={
            <SuspensedView model='role'>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/section/*'
          element={
            <SuspensedView model='section'>
              <SectionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/admin/school/*'
          element={
            <SuspensedView model='school'>
              <SchoolsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/frontend/slider/*'
          element={
            <SuspensedView model='slider'>
              <SlidersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/frontend/about/*'
          element={
            <SuspensedView model='about'>
              <AboutsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/student-management/student-attendance/*'
          element={
            <SuspensedView model='student-attendance'>
              <StudentAttendancesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/student-management/student-type/*'
          element={
            <SuspensedView model='student-type'>
              <StudentTypesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/student-management/student/*'
          element={
            <SuspensedView model='student'>
              <StudentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/subject/*'
          element={
            <SuspensedView model='subject'>
              <SubjectsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/study-material/*'
          element={
            <SuspensedView model='studymaterial'>
              <StudyMaterialsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/scholarship/*'
          element={
            <SuspensedView model='scholarship'>
              <Scholarship />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donor/*'
          element={
            <SuspensedView model='donor'>
              <SsDonorPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donation/*'
          element={
            <SuspensedView model='donation'>
              <SsDonationPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/candidate/*'
          element={
            <SuspensedView model='candidate'>
              <SsCandidatePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/routine/*'
          element={
            <SuspensedView model='routine'>
              <RoutinesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/syllabus/*'
          element={
            <SuspensedView model='syllabus'>
              <SyllabussPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/class-lecture/*'
          element={
            <SuspensedView model='class-lecture'>
              <ClassLecturesPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren & {model: string}> = ({children, model}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  const {privilege} = useAuth()
  const visible = () => {
    let res = false
    const result = privilege?.find((p) => p.operation?.operation_slug === model)
    if (result) res = result['is_view'] || false
    return res
  }
  return visible() ? (
    <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
  ) : (
    <Routes>
      <Route path='*' element={<Navigate to='/dashboard' />} />
    </Routes>
  )
}
const SuspensedView1: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
