import axios from 'axios'
import {Privilege, PrivilegesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_V2_URL
const PRIVILEGE_URL = `${API_URL}/privilege`

const getPrivileges = (query?: string): Promise<PrivilegesQueryResponse> => {
  return axios.get(`${PRIVILEGE_URL}?${query}`)
}

const updatePrivilege = (privilege: Privilege[]): Promise<Privilege[] | undefined> => {
  return axios.patch(`${PRIVILEGE_URL}`, privilege)
}

export {getPrivileges, updatePrivilege}
