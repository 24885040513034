const QUERIES = {
  ABOUTS_LIST: 'abouts-list',
  ANNOUNCEMENTS_LIST: 'announcements-list',
  ACADEMIC_YEARS_LIST: 'academic-years-list',
  SUMMARY_LIST: 'summary-list',
  ACCOUNTS_LIST: 'accounts-list',
  ASSET_CATEGORYS_LIST: 'asset-categorys-list',
  ASSET_ISSUES_LIST: 'asset-issues-list',
  ASSET_ITEMS_LIST: 'asset-items-list',
  ASSET_PURCHASES_LIST: 'asset-purchases-list',
  ASSET_STOCKS_LIST: 'asset-stocks-list',
  ADMIT_CARD_SETTINGS_LIST: 'admit-card-settings-list',
  ACTIVITY_LOGS_LIST: 'activity-logs-list',
  ADMISSIONS_LIST: 'admissions-list',
  ASSET_STORES_LIST: 'asset-stores-list',
  ASSIGNMENT_SUBMISSIONS_LIST: 'assignment-submissions-list',
  ASSIGNMENTS_LIST: 'assignments-list',
  AWARDS_LIST: 'awards-list',
  BOOKS_LIST: 'books-list',
  BOOK_ISSUES_LIST: 'book-issues-list',
  CERTIFICATES_LIST: 'certificates-list',
  CLASSS_LIST: 'classs-list',
  CLASS_GROUPS_LIST: 'class-groups-list',
  COMPLAIN_TYPES_LIST: 'complain-types-list',
  COMPLAINS_LIST: 'complains-list',
  DEPARTMENTS_LIST: 'departments-list',
  DESIGNATIONS_LIST: 'designations-list',
  EBOOKS_LIST: 'ebooks-list',
  EMAIL_SETTINGS_LIST: 'email-settings-list',
  EMAILS_LIST: 'emails-list',
  DISCOUNTS_LIST: 'discounts-list',
  FEE_TYPES_LIST: 'fee-type-list',
  EMPLOYEE_ATTENDANCES_LIST: 'employee-attendances-list',
  ENROLLMENTS_LIST: 'enrollments-list',
  EMAIL_TEMPLATES_LIST: 'email-templates-list',
  EMPLOYEES_LIST: 'employees-list',
  EVENTS_LIST: 'events-list',
  EXAM_ANSWERS_LIST: 'exam-answers-list',
  EXAM_ATTENDANCES_LIST: 'exam-attendances-list',
  EXAM_INSTRUCTIONS_LIST: 'exam-instructions-list',
  EXAM_ONLINE_EXAMS_LIST: 'exam-online-exams-list',
  EXAM_QUESTIONS_LIST: 'exam-questions-list',
  EXAM_RESULTS_LIST: 'exam-results-list',
  EXAM_SCHEDULES_LIST: 'exam-schedules-list',
  EXAM_TAKEN_EXAMS_LIST: 'exam-taken-exams-list',
  EXAMS_LIST: 'exams-list',
  EXPENDITURE_HEADS_LIST: 'expenditure-heads-list',
  FAQS_LIST: 'faqs-list',
  EXAM_TO_QUESTIONS_LIST: 'exam-to-questions-list',
  FEES_AMOUNTS_LIST: 'fees-amounts-list',
  GALLERY_IMAGES_LIST: 'gallery-images-list',
  GALLERYS_LIST: 'gallerys-list',
  FINAL_RESULTS_LIST: 'final-results-list',
  GMSMS_SESSIONS_LIST: 'gmsms-sessions-list',
  GRADES_LIST: 'grades-list',
  HOLIDAYS_LIST: 'holidays-list',
  HOSTEL_MEMBERS_LIST: 'hostel-members-list',
  GLOBAL_SETTINGS_LIST: 'global-settings-list',
  INCOME_HEADS_LIST: 'income-heads-list',
  INVOICES_LIST: 'invoices-list',
  ID_CARD_SETTINGS_LIST: 'id-card-settings-list',
  ITEM_CATEGORYS_LIST: 'item-categorys-list',
  EXPENDITURES_LIST: 'expenditures-list',
  ITEM_PRODUCTS_LIST: 'item-products-list',
  INVOICE_DETAILS_LIST: 'invoice-details-list',
  ITEM_SALES_LIST: 'item-sales-list',
  ITEM_PURCHASES_LIST: 'item-purchases-list',
  ITEM_STOCKS_LIST: 'item-stocks-list',
  ITEM_SUPPLIERS_LIST: 'item-suppliers-list',
  FEEDBACKS_LIST: 'feedbacks-list',
  LANGUAGES_LIST: 'languages-list',
  LEAVE_APPLICATIONS_LIST: 'leave-applications-list',
  LEAVE_TYPES_LIST: 'leave-types-list',
  LIBRARY_MEMBERS_LIST: 'library-members-list',
  LIVE_CLASSS_LIST: 'live-classs-list',
  LP_LESSON_DETAILS_LIST: 'lp-lesson-details-list',
  LP_LESSONS_LIST: 'lp-lessons-list',
  LP_TOPIC_DETAILS_LIST: 'lp-topic-details-list',
  LP_TOPICS_LIST: 'lp-topics-list',
  MARK_EMAILS_LIST: 'mark-emails-list',
  MARKS_LIST: 'marks-list',
  MARKSHEET_LIST: 'marksheet-list',
  MARK_SMSES_LIST: 'mark-smses-list',
  MESSAGE_RELATIONSHIPS_LIST: 'message-relationships-list',
  MESSAGES_LIST: 'messages-list',
  MODULES_LIST: 'modules-list',
  NEWSS_LIST: 'news-list',
  NOTICES_LIST: 'notices-list',
  OPENING_HOURS_LIST: 'opening-hours-list',
  OPERATIONS_LIST: 'operations-list',
  PAYMENT_SETTINGS_LIST: 'payment-settings-list',
  PAGES_LIST: 'pages-list',
  PHONE_CALL_LOGS_LIST: 'phone-call-logs-list',
  POSTAL_RECEIPTS_LIST: 'postal-receipts-list',
  PRIVILEGES_LIST: 'privileges-list',
  POSTAL_DISPATCHS_LIST: 'postal-dispatchs-list',
  PROFILE: 'profile',
  PROMOTIONS_LIST: 'promotions_list',
  PURCHASES_LIST: 'purchases-list',
  EXAM_PSYCHOMOTORS_LIST: 'psychomotors-list',
  RATINGS_LIST: 'ratings-list',
  REPLYS_LIST: 'replys-list',
  RESULTS_LIST: 'results-list',
  RESULT_CARDS_LIST: 'result-cards-list',
  ROUTE_STOPS_LIST: 'route-stops-list',
  ROLES_LIST: 'roles-list',
  ROOMS_LIST: 'rooms-list',
  ROUTES_LIST: 'routes-list',
  ROUTINES_LIST: 'routines-list',
  SAAS_FAQS_LIST: 'saas-faqs-list',
  SAAS_PLANS_LIST: 'saas-plans-list',
  SAAS_SETTINGS_LIST: 'saas-settings-list',
  SAAS_SLIDERS_LIST: 'saas-sliders-list',
  SAAS_SUBSCRIPTIONS_LIST: 'saas-subscriptions-list',
  SALARY_GRADES_LIST: 'salary-grades-list',
  ITEM_WAREHOUSES_LIST: 'item-warehouses-list',
  SECTIONS_LIST: 'sections-list',
  SCHOOLS_LIST: 'schools-list',
  SLIDERS_LIST: 'sliders-list',
  HOSTELS_LIST: 'hostels-list',
  SMS_SETTINGS_LIST: 'sms-settings-list',
  SS_BALANCES_LIST: 'ss-balances-list',
  SS_CANDIDATES_LIST: 'ss-candidates-list',
  SS_DONORS_LIST: 'ss-donors-list',
  SS_SCHOLARSHIPS_LIST: 'ss-scholarships-list',
  STUDENT_ACTIVITYS_LIST: 'student-activitys-list',
  STUDENT_ATTENDANCES_LIST: 'student-attendances-list',
  STUDENT_TYPES_LIST: 'student-types-list',
  STUDENTS_LIST: 'students-list',
  STUDY_MATERIALS_LIST: 'study-materials-list',
  SUBJECTS_LIST: 'subjects-list',
  SUGGESTIONS_LIST: 'suggestions-list',
  SYLLABUSS_LIST: 'syllabuss-list',
  TEACHER_ATTENDANCES_LIST: 'teacher-attendances-list',
  TEACHERS_LIST: 'teachers-list',
  SYSTEM_ADMINS_LIST: 'system-admins-list',
  TEXT_MESSAGES_LIST: 'text-messages-list',
  THEMES_LIST: 'themes-list',
  TRANSACTIONS_LIST: 'transactions-list',
  TRANSPORT_MEMBERS_LIST: 'transport-members-list',
  TODOS_LIST: 'todos-list',
  USERS_LIST: 'users-list',
  VEHICLES_LIST: 'vehicles-list',
  VISITORS_LIST: 'visitors-list',
  VISITOR_PURPOSES_LIST: 'visitor-purposes-list',
  CLASS_LECTURES_LIST: 'class-lectures-list',
  SALARY_PAYMENTS_LIST: 'salary-payments-list',
  SMS_TEMPLATES_LIST: 'sms-templates-list',
  ITEM_ISSUES_LIST: 'item-issues-list',
}

export {QUERIES}
