/* eslint-disable react/jsx-no-target-blank */
import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {privilege} = useAuth()
  const routes = [
    {
      to: '/apps/admin',
      title: 'Adminstartor',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/finance/fin001.svg',
      children: [
        {
          to: '/apps/admin/school',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'School',
          model: 'school',
        },
        {
          to: '/apps/admin/academic-year',
          title: 'Academic Year',
          icon: '/media/icons/duotune/general/gen022.svg',
          model: 'academic-year',
        },
        {
          to: '/apps/admin/role',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Role',
          model: 'role',
        },
        {
          to: '/apps/admin/permission',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Permission',
          model: 'permission',
        },
        {
          to: '/apps/admin/account',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'User',
          model: 'account',
        },
      ],
    },
    {
      to: '/apps/employee-management',
      title: 'Employee Management',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/communication/com014.svg',
      children: [
        {
          to: '/apps/employee-management/employee',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Employee',
          model: 'employee',
        },
        {
          to: '/apps/employee-management/employee-attendance',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Employee Attendance',
          model: 'employee-attendance',
        },
        {
          to: '/apps/employee-management/department',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Department',
          model: 'department',
        },
        {
          to: '/apps/employee-management/designation',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Designation',
          model: 'designation',
        },
      ],
    },
    {
      to: '/apps/academic',
      title: 'Academic',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/finance/fin006.svg',
      children: [
        {
          to: '/apps/academic/class',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Classes',
          model: 'class',
        },
        {
          to: '/apps/academic/section',
          title: 'Section',
          icon: '/media/icons/duotune/general/gen022.svg',
          model: 'section',
        },
        {
          to: '/apps/academic/subject',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Subject',
          model: 'subject',
        },
        {
          to: '/apps/academic/syllabus',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Syllabus',
          model: 'syllabus',
        },
        {
          to: '/apps/academic/study-material',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Study material',
          model: 'study-material',
        },
        {
          to: '/apps/academic/class-lecture',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Class lecture',
          model: 'class-lecture',
        },
        {
          to: '/apps/academic/assignment',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Assignment',
          model: 'assignment',
        },
        {
          to: '/apps/academic/assignment-submission',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Assignment Submission',
          model: 'assignment-submission',
        },

        {
          to: '/apps/academic/study-material',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Study Material',
          model: 'studymaterial',
        },
        {
          to: '/apps/academic/routine',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Routine',
          model: 'routine',
        },
      ],
    },
    {
      to: '/apps/student-management',
      title: 'Student Management',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/communication/com006.svg',
      children: [
        {
          to: '/apps/student-management/student',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Student',
          model: 'student',
        },
        {
          to: '/apps/student-management/student-attendance',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Student Attendance',
          model: 'student-attendance',
        },
        {
          to: '/apps/student-management/student-type',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Student Type',
          model: 'student-type',
        },
        {
          to: '/apps/student-management/admission',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Online Admission',
          model: 'admission',
        },
      ],
    },
    {
      to: '/apps/id-cards',
      title: 'ID Card',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/technology/teh002.svg',
      children: [
        {
          to: '/apps/id-cards/student-id-card',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Student ID Card',
          model: 'student-id-card',
        },
        {
          to: '/apps/id-cards/employee-id-card',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Employee ID Card',
          model: 'employee-id-card',
        },
      ],
    },
    {
      to: '/apps/exam',
      title: 'Exam',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/general/gen055.svg',
      children: [
        {
          to: '/apps/exam/exam',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Exam',
          model: 'exam',
        },
        {
          to: '/apps/exam/schedule',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Schedule',
          model: 'schedule',
        },
        {
          to: '/apps/exam/grade',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Grade',
          model: 'grade',
        },
        {
          to: '/apps/exam/exam-attendance',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Attendance',
          model: 'exam-attendance',
        },
      ],
    },
    {
      to: '/apps/mark',
      title: 'Exam Mark',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/ecommerce/ecm011.svg',
      children: [
        {
          to: '/apps/mark',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Mark',
          model: 'mark',
        },
        {
          to: '/apps/marksheet',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Marksheet',
          model: 'marksheet',
        },
        {
          to: '/apps/psychomotor/marksheet',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Psychomotor',
          model: 'psychomotor',
        },
        {
          to: '/apps/examresult',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Exam Term Result',
          model: 'examresult',
        },
        // {
        //   to: '/apps/finalresult',
        //   icon: '/media/icons/duotune/general/gen022.svg',
        //   title: 'Exam Final Result',
        //   model: 'finalresult',
        // },
        {
          to: '/apps/resultcard',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Result Card',
          model: 'resultcard',
        },
      ],
    },
    {
      to: '/apps/promotion',
      icon: '/media/icons/duotune/arrows/arr056.svg',
      title: 'Promotion',
      fontIcon: 'bi-app-indicator',
      model: 'promotion',
    },
    {
      to: '/apps/asset',
      title: 'Asset Management',
      icon: '/media/icons/duotune/general/gen019.svg',
      children: [
        {
          to: '/apps/category',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Asset Categories',
          fontIcon: 'bi-layers',
          model: 'asset-category',
        },
        {
          to: '/apps/store',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Asset Store',
          fontIcon: 'bi-layers',
          model: 'asset-store',
        },
        {
          to: '/apps/item',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Asset Items',
          fontIcon: 'bi-layers',
          model: 'asset-item',
        },
        // { to: '/apps/purchase', icon: '/media/icons/duotune/general/gen022.svg', title: 'Asset Purchase', fontIcon: 'bi-layers' },
        // { to: '/apps/todo', icon: '/media/icons/duotune/general/gen022.svg', title: 'Asset Issue & Return', fontIcon: 'bi-layers' },
      ],
    },
    {
      to: '/apps/leave',
      title: 'Leave',
      icon: '/media/icons/duotune/general/gen009.svg',
      children: [
        {
          to: '/apps/application',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Leave Application',
          fontIcon: 'bi-layers',
          model: 'application',
        },
        {
          to: '/apps/application/type',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Leave Type',
          fontIcon: 'bi-layers',
          model: 'type',
        },
      ],
    },
    // { to: '/apps/Complains', title: 'Complain', icon: '/media/icons/duotune/general/gen009.svg', children: [
    //   { to: '/apps/complain/type', icon: '/media/icons/duotune/general/gen022.svg', title: 'Complain Type', fontIcon: 'bi-layers' },
    //   { to: '/apps/complain', icon: '/media/icons/duotune/general/gen022.svg', title: 'Complains', fontIcon: 'bi-layers' },
    // ] },
    {
      to: '/apps/library-member',
      title: 'Library',
      icon: '/media/icons/duotune/files/fil002.svg',
      children: [
        {
          to: '/apps/library-member',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Library Member',
          fontIcon: 'bi-layers',
          model: 'library-member',
        },
        {
          to: '/apps/book',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Book',
          fontIcon: 'bi-layers',
          model: 'book',
        },
        {
          to: '/apps/book/issue',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Issue & Return',
          fontIcon: 'bi-layers',
          model: 'issue',
        },
        {
          to: '/apps/ebook',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'E~Book',
          fontIcon: 'bi-layers',
          model: 'ebook',
        },
      ],
    },
    {
      to: '/apps/hostel',
      title: 'Hostel',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/general/gen001.svg',
      children: [
        // { to: '/apps/member', icon: '/media/icons/duotune/general/gen022.svg', title: 'Hostel Member', fontIcon: 'bi-layers' },
        {
          to: '/apps/hostel',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Hostel',
          fontIcon: 'bi-layers',
          model: 'hostel',
        },
        {
          to: '/apps/room',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Rooms',
          fontIcon: 'bi-layers',
          model: 'room',
        },
      ],
    },
    //DISCOUNT
    {
      to: '/apps/discount',
      title: 'Accounting',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/finance/fin001.svg',
      children: [
        {
          to: '/apps/discount',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Discount',
          fontIcon: 'bi-layers',
          model: 'discount',
        },
        {
          to: '/apps/expenditure',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Expenditure',
          fontIcon: 'bi-layers',
          model: 'expenditure',
        },
        {
          to: '/apps/expenditure/exphead',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Expenditure Head',
          fontIcon: 'bi-layers',
          model: 'exphead',
        },
        {
          to: '/apps/fee-type',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Fee Type',
          fontIcon: 'bi-layers',
          model: 'invoice',
        },
        {
          to: '/apps/invoice',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Invoice',
          fontIcon: 'bi-layers',
          model: 'invoice',
        },
        {
          to: '/apps/transaction',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Transaction',
          fontIcon: 'bi-layers',
          model: 'invoice',
        },
      ],
    },
     {
      to: '/apps/scholarship',
      title: 'Scholarship',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/general/gen001.svg',
      children: [
        {
          to: '/apps/scholarship',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Scholaship',
          fontIcon: 'bi-layers',
          model: 'scholarship',
        },
        {
          to: '/apps/donor',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Donor',
          fontIcon: 'bi-layers',
          model: 'donor',
        },
        {
          to: '/apps/donation',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Donation',
          fontIcon: 'bi-layers',
          model: 'donation',
        },
        {
          to: '/apps/candidate',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Candidate',
          fontIcon: 'bi-layers',
          model: 'candidate',
        },
      ],
    },
    {
      to: '/apps/frontend',
      title: 'Manage Frontend',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/general/gen001.svg',
      children: [
        {
          to: '/apps/frontend/slider',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Slider',
          model: 'slider',
        },
        {
          to: '/apps/frontend/about',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'About Us',
          model: 'about',
        },
        {
          to: '/apps/frontend/news',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Announcement',
          model: 'news',
        },
      ],
    },
    {
      to: '/apps/gallery',
      title: 'Gallery',
      fontIcon: 'bi-chat-left',
      icon: '/media/icons/duotune/general/gen006.svg',
      children: [
        {to: '/apps/gallery', icon: '/media/icons/duotune/general/gen022.svg', title: 'Gallery'},
        {
          to: '/apps/gallery/image',
          icon: '/media/icons/duotune/general/gen022.svg',
          title: 'Gallery Images',
          model: 'image',
        },
      ],
    },
  ]
  const allowedRoutes = useMemo(() => {
    const data: any = []
    routes.forEach((route: any) => {
      if (route.model) {
        const model = route.model
        if (model && !privilege?.find((p) => p.operation?.operation_slug === model)?.is_view) return
      }
      if (!route.children) {
        data.push(route)
        return
      }
      const allowedChildren = route.children.filter((child: any) => {
        const model = child.model
        if (model) {
          return privilege?.find((p) => p.operation?.operation_slug === model)?.is_view
        }
        return true
      })
      if (allowedChildren.length > 0) {
        data.push({
          ...route,
          children: allowedChildren,
        })
      }
    })
    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilege])
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen008.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      {allowedRoutes.map((route: any) => {
        if (route.children) {
          return (
            <AsideMenuItemWithSub
              key={route.to}
              to={route.to}
              title={route.title}
              fontIcon={route.fontIcon}
              icon={route.icon}
            >
              {route.children.map((child: any) => (
                <AsideMenuItem key={child.to} to={child.to} icon={child.icon} title={child.title} />
              ))}
            </AsideMenuItemWithSub>
          )
        }
        return <AsideMenuItem key={route.to} to={route.to} icon={route.icon} title={route.title} />
      })}
    </>
  )
}
